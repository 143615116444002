<template>
  <a-form
    labelAlign="right"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    :model="websiteSet"
    :rules="rules"
    @finish="fromFinish"
    style="padding: 50px 80px"
  >
    <a-form-item name="title" label="网站标题">
      <a-input
        v-model:value="websiteSet.title"
        type="text"
        autocomplete="off"
        placeholder="请输入网站标题"
      />
    </a-form-item>

    <a-form-item name="logo" label="网站LOGO">
      <a-input
        v-model:value="websiteSet.logo"
        type="text"
        autocomplete="off"
        placeholder="请选择或上传网站logo"
        style="display: flex"
      >
        <template #addonAfter>
          <a-button
            type="primary"
            style="
              position: relative;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            "
            @click="chooseFile"
            >选择图片
          </a-button>
          <a-modal v-model:visible="visible" title="选择图片" width="1088px">
            <template #footer>
              <a-button key="submit" type="primary" @click="chooseItem"
                >选择</a-button
              >
            </template>
            <file></file>
          </a-modal>
        </template>
      </a-input>
      <div
        style="
          width: 150px;
          height: 150px;
          margin-top: 20px;
          border: 1px solid #d9d9d9;
          position: relative;
        "
      >
        <img
          :src="logo"
          alt=""
          style="width: 100%; height: 100%; object-fit: contain"
        />
        <IconFont
          type="wapi-close-circle"
          style="position: absolute; right: -20px; top: 0; color: #9d9d9d"
          @click="deleteImage"
        />
      </div>
    </a-form-item>

    <a-form-item name="keywords" label="关键词">
      <a-input
        v-model:value="websiteSet.keywords"
        type="text"
        autocomplete="off"
        placeholder="请输入网站关键词，以空格或【,】隔开"
      />
    </a-form-item>

    <a-form-item label="网站描述" name="description">
      <a-textarea
        v-model:value="websiteSet.description"
        placeholder="请输入网站描述信息"
      />
    </a-form-item>

    <a-form-item name="icp" label="ICP备案号">
      <a-input
        v-model:value="websiteSet.icp"
        type="text"
        autocomplete="off"
        placeholder="ICP备案号"
      />
    </a-form-item>

    <a-form-item>
      <a-button type="primary" html-type="submit">提交 </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import {
  Form,
  FormItem,
  Input,
  Button,
  Textarea,
  Modal,
  message,
} from "ant-design-vue/es";

import state from "../../store/state";
import set from "../../api/set";
import File from "../../components/File";
import eventBus from "vue3-eventbus";
import IconFont from "../../utils/IconFont";

export default {
  name: "Web",
  data() {
    return {
      visible: false,
      websiteSet: {},
      chooseItemsUrl: [],
      logo: "https://oss.pigwan.net/wapi/images/static/default-pic.jpg?x-oss-process=style/wapi_file_list",
      labelCol: {
        style: {
          width: "80px",
        },
      },
      wrapperCol: {
        span: 18,
      },
      rules: {
        title: [
          {
            required: true,
            message: "请输入网站标题",
            trigger: "blur",
          },
          {
            min: 3,
            max: 64,
            message: "标题长度在3-64个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    File,
    IconFont,
    AForm: Form,
    AFormItem: FormItem,
    AInput: Input,
    AButton: Button,
    ATextarea: Textarea,
    AModal: Modal,
  },
  created() {
    this.getWebsiteSet();
  },
  mounted() {
    eventBus.on("chooseItemsUrl", (val) => {
      this.chooseItemsUrl = val;
    });
  },
  methods: {
    //获取网站配置信息
    getWebsiteSet() {
      let that = this;
      set
        .getWebsiteSet()
        .then((res) => {
          if (res.code === 0) {
            that.websiteSet = res.data;
            if (that.websiteSet.logo !== "" && that.websiteSet.logo !== null) {
              that.logo =
                "https://oss.pigwan.net" +
                that.websiteSet.logo +
                "?x-oss-process=style/wapi_file_list";
            }
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    chooseFile() {
      this.visible = true;
    },
    //提交数据
    fromFinish(data) {
      data.website_id = state.adminInfo.website.id;
      set
        .updateWebsiteSet(data)
        .then((res) => {
          if (res.code === 0) {
            message.success(res.msg);
            this.getWebsiteSet();
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.message
            );
          }
        })
        .catch((e) => {
          let error = JSON.parse(JSON.stringify(e));
          message.error(
            "出现错误，请联系管理员！错误代码：" +
              error.status +
              ",错误信息：" +
              error.message
          );
        });
    },
    chooseItem() {
      if (this.chooseItemsUrl.length !== 1) {
        message.info("只能选择1张图片");
        return false;
      } else {
        this.websiteSet.logo = this.chooseItemsUrl[0];
        this.logo =
          "https://oss.pigwan.net" +
          this.websiteSet.logo +
          "?x-oss-process=style/wapi_file_list";
        this.visible = false;
      }
    },
    deleteImage() {
      this.websiteSet.logo = "";
      this.logo =
        "https://oss.pigwan.net/wapi/images/static/default-pic.jpg?x-oss-process=style/wapi_file_list";
    },
  },
};
</script>

<style scoped lang="css">
.ant-input-group-addon {
  padding: 0 !important;
  border: 0 !important;
}
</style>
